import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // primary: "#060D36",
        primary: "#060A23",
        secondary: "#0D2F64",
        capabilityCard: "#12172E",
      },
    },
  },
});
