<template>
  <section class="text-left" id="/">
    <!-- <div class="text-left"> -->
    <v-card class="transparent hidden-sm-and-down" height="100" flat></v-card>

    <v-container class="hidden-sm-and-down">
      <v-card
        class="transparent d-flex flex-column align-center justify-center mt-md-16"
        flat
        width="100%"
      >
        <v-container class="d-flex hidden-sm-and-down">
          <div class="mt-md-16">
            <v-img
              alt="Logo"
              class=""
              src="@/assets/images/SectionOne/Arrow2.png"
              width="150px"
              contain
            />
          </div>
          <div class="mt-16">
            <label class="heading-h1"> Where Business Meets Technology </label>
            <p class="mt-7 paragraph-p3">
              Our products combine bank functionality and the most advanced
              cloud-native, cloud agnostic, AI and API-first technology.
            </p>

            <div class="d-flex mt-7">
              <a href="#contacts">
                <v-btn
                  height="55"
                  block="true"
                  rounded
                  color="blue"
                  class="px-7"
                  dark
                >
                  <v-span class="lets mt-n1">Let’s </v-span>
                  <p class="paragraph-btn1">GET STARTED</p>
                </v-btn>
              </a>

              <a href="#learnMore" class="ml-5">
                <v-btn
                  height="55"
                  block="true"
                  rounded
                  color="  blue"
                  outlined
                  class="ml-3 px-7"
                  dark
                >
                  <p class="paragraph-btn1 black--text">LEARN MORE</p>
                </v-btn>
              </a>
            </div>
          </div>

          <v-card
            class="transparent hidden-sm-and-down"
            width="150"
            flat
          ></v-card>
          <div class="pl-12">
            <v-img
              alt="Logo"
              class="bankCard"
              src="@/assets/images/SectionOne/sherlock.png"
              contain
            />
          </div>
        </v-container>
      </v-card>

      <!-- companies logo-->

      <v-card
        class="transparent px-md-16 pt-md-10 d-flex align-center justify-center mt-16"
        flat
        tile
      >
        <v-card
          width="320px"
          class="d-flex align-center justify-center transparent hidden-sm-and-down"
          flat
        >
          <v-img
            alt="Logo"
            class="mt-1"
            height="50px"
            contain
            src="@/assets/images/SectionOne/Moola.jpeg"
          />
          <v-img
            alt="Logo"
            class=""
            height="65px"
            contain
            src="@/assets/images/SectionOne/linder.png"
          />
          <v-img
            alt="Logo"
            class=""
            height="50px"
            contain
            src="@/assets/images/SectionOne/lime.png"
          />
        </v-card>
      </v-card>
      <!-- <v-card class="transparent pt-10" height="200" flat>
        <v-row class="mt-md-16 mb-16">
          <v-col cols="12" md="2"> </v-col> -->

      <!-- moola -->
      <!-- <v-col cols="12" md="3" offset="3">
            <v-img
              alt="Logo"
              class="mt-3"
              contain
              width="320"
              src="@/assets/images/SectionOne/Moola.jpeg"
            />
          </v-col> -->

      <!-- Lime -->
      <!-- <v-col class="mx-3" cols="12" md="2">
            <v-img
              alt="Logo"
              class="mt-2"
              width="164"
              contain
              src="@/assets/images/SectionOne/lime.png"
            />
          </v-col> -->

      <!-- ubank -->
      <!-- <v-col cols="12" md="3">
            <v-img
              alt="Logo"
              class="mt-2"
              width="209"
              contain
              src="@/assets/images/SectionOne/linder.png"
            />
          </v-col> -->
      <!-- </v-row>
      </v-card> -->

      <!-- card after logos -->
    </v-container>

    <!-- mobile -->

    <v-container class="mt-16">
      <v-card
        class="transparent d-flex flex-column align-center justify-center mt-6"
        flat
        width="100%"
      >
        <!-- mobile -->
        <v-container class="hidden-md-and-up">
          <v-row>
            <v-col cols="12" md="5">
              <v-img
                alt="Logo"
                class=""
                src="@/assets/images/SectionOne/sherlock.png"
                contain
                width="600"
              />
            </v-col>

            <v-col cols="12" md="6">
              <div class="mainClass">
                <div class="imageClass">
                  <v-img
                    alt="Logo"
                    class=""
                    src="@/assets/images/SectionOne/Arrow3.png"
                    width="100px"
                    contain
                  />
                </div>
                <div>
                  <h2 class="heading-h1">Where Business Meets Technology</h2>
                  <p class="paragraph-p3">
                    Our products combine bank functionality and the most
                    advanced cloud-native, cloud agnostic, AI and API-first
                    technology.
                  </p>
                </div>
              </div>
              <!-- <div class="d-flex">
                <div>
                  <h2 class="heading-h1">Where Business Meets Technology</h2>
                  <p class="paragraph-p3">
                    Our products combine bank functionality and the most
                    advanced cloud-native, cloud agnostic, AI and API-first
                    technology.
                  </p>
                </div>

                <div>
                  <v-img
                    alt="Logo"
                    class=""
                    src="@/assets/images/SectionOne/Arrow3.png"
                    width="100px"
                    contain
                  />
                </div>
              </div> -->

              <!-- mobile buttons -->
              <v-row class="mt-5">
                <v-col cols="12" md="5">
                  <v-btn
                    height="55"
                    block="true"
                    rounded
                    color="blue"
                    class="px-7"
                    dark
                  >
                    <v-span class="lets">Let’s </v-span>
                    <p class="paragraph-btn1">GET STARTED</p>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="5">
                  <v-btn
                    height="55"
                    block="true"
                    rounded
                    color="  blue"
                    outlined
                    class="px-5"
                    dark
                  >
                    <p class="paragraph-btn1 black--text">LEARN MORE</p>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="my-10">
            <v-col cols="4" md="4">
              <v-img
                alt="Logo"
                class="mt-3"
                width="100"
                src="@/assets/images/SectionOne/Moola.jpeg"
              />
            </v-col>

            <v-col cols="4" md="4">
              <v-img
                alt="Logo"
                class="mt-2"
                width="100"
                src="@/assets/images/SectionOne/lime.png"
              />
            </v-col>

            <v-col cols="4" md="4">
              <v-img
                alt="Logo"
                class=""
                width="100"
                src="@/assets/images/SectionOne/linder.png"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    <!-- </div> -->
  </section>
</template>

<script>
export default {
  name: "SectionOne",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
//

a {
  text-decoration: none;
}
.lets {
  margin-top: 0px;
  margin-right: 2px;
  font-size: 12px;
}
.mainClass {
  position: relative;
}
.imageClass {
  position: absolute;
  right: -25px;
  top: 40px;
}
.bankCard {
  width: 502px;
}

@media screen and (max-width: 1440px) {
  .bankCard {
    width: 400px;
    margin-top: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .bankCard {
    width: 300px;
    margin-top: 50px;
  }
}
@media screen and (max-width: 768px) {
  .imageClass {
    position: absolute;
    right: -25px;
    top: -15px;
  }
  .companieLogo {
    margin-left: -18px;
  }
}
@media screen and (max-width: 425px) {
  .imageClass {
    position: absolute;
    right: -27px;
    top: 30px;
  }
  .companieLogo {
    margin-left: -45px;
  }
}

@media screen and (max-width: 375px) {
  .imageClass {
    position: absolute;
    right: -35px;
    top: -10px;
  }

  @media screen and (max-width: 375px) {
    .imageClass {
      position: absolute;
      right: -25px;
      top: -10px;
    }
  }
}
</style>
