<template>
  <div class="nav">
    <v-container>
      <v-row class="py-5 white--text">
        <v-btn
          @click.stop="drawer = !drawer"
          icon
          class="hidden-md-and-up mb-5 ml-3 mr-4 mt-2"
          width="18"
          color="black"
        >
          <v-icon> mdi-menu </v-icon>
        </v-btn>

        <a
          id="aboutUs"
          class="nav-link text-decoration-none mt-2"
          href="#"
          @click="
            about = false;
            team = false;
            journey = false;
            contacts = false;
          "
        >
          <v-img
            alt="Logo"
            class="OLSLogo"
            src="@/assets/images/navBar/OLSLogo.png"
        /></a>

        <!-- <router-link class="omniLogo text-decoration-none" to="/">
        
          <v-img
            alt="Logo"
            class="OLSLogo"
            src="@/assets/images/navBar/OLSLogo.png"
          />
        
        </router-link> -->
        <v-spacer></v-spacer>

        <div>
          <v-row class="navB nav-link">
            <!-- omnisoft -->
            <a
              class="nav-link text-decoration-none mx-6 hidden-sm-and-down"
              href="http://softidoc.com/"
            >
              <p class="paragraph-p1 mt-7 hidden-sm-and-down">Home</p>
            </a>

            <!-- About -->

            <a
              id="aboutUs"
              class="nav-link text-decoration-none mx-6 hidden-sm-and-down"
              href="#about"
              @click="
                about = true;
                team = false;
                journey = false;
                contacts = false;
              "
            >
              <p
                v-if="about == false"
                class="paragraph-p1 mt-7 hidden-sm-and-down"
              >
                About
              </p>
              <p
                v-if="about == true"
                class="paragraph-p1 mt-6 hidden-sm-and-down blue--text"
              >
                About
              </p>
              <v-card
                v-if="about == true"
                class="transparent mt-n2 hidden-sm-and-down"
                align="center"
                flat
                ><v-card class="dot blue"></v-card
              ></v-card>
            </a>

            <!-- Team -->

            <a
              class="nav-link text-decoration-none mx-3 hidden-sm-and-down"
              href="#team"
              @click="
                about = false;
                team = true;
                journey = false;
                contacts = false;
              "
            >
              <p
                v-if="team == false"
                class="paragraph-p1 mt-7 hidden-sm-and-down"
              >
                Team
              </p>
              <p
                v-if="team == true"
                class="paragraph-p1 mt-6 hidden-sm-and-down blue--text"
              >
                Team
              </p>
              <v-card
                v-if="team == true"
                class="transparent mt-n2 hidden-sm-and-down"
                align="center"
                flat
                ><v-card class="dot blue"></v-card
              ></v-card>
            </a>

            <!-- Client Journey -->

            <a
              class="nav-link text-decoration-none mx-6 hidden-sm-and-down"
              href="#clientJourney"
              @click="
                about = false;
                team = false;
                journey = true;
                contacts = false;
              "
            >
              <p
                v-if="journey == false"
                class="paragraph-p1 mt-7 hidden-sm-and-down"
              >
                Client Journey
              </p>
              <p
                v-if="journey == true"
                class="paragraph-p1 mt-6 hidden-sm-and-down blue--text"
              >
                Client Journey
              </p>
              <v-card
                v-if="journey == true"
                class="transparent mt-n2 hidden-sm-and-down"
                align="center"
                flat
                ><v-card class="dot blue"></v-card
              ></v-card>
            </a>

            <!-- login -->

            <a
              class="nav-link text-decoration-none mx-6 hidden-sm-and-down"
              href="http://development.softidoc.co.za/"
            >
              <p class="paragraph-p1 mt-7 hidden-sm-and-down">Log in</p>
            </a>

            <!-- contact us -->

            <a
              class="nav-link text-decoration-none ml-5 mr-9"
              href="#contacts"
              @click="
                about = false;
                team = false;
                journey = false;
                contacts = false;
              "
            >
              <p v-if="contacts == false" class="paragraph-p1 mr-2 mt-7">
                Contact Us
              </p>
              <p
                v-if="contacts == true"
                class="paragraph-p1 mr-2 mt-6 blue--text"
              >
                Contact Us
              </p>
              <v-card
                v-if="contacts == true"
                class="transparent mt-n2"
                align="center"
                flat
                ><v-card class="dot blue"></v-card
              ></v-card>
            </a>
          </v-row>
        </div>
      </v-row>

      <!-- side bar -->

      <v-navigation-drawer
        class="primary sideBar text-justify"
        v-model="drawer"
        absolute
        temporary
      >
        <a id="aboutUs" class="nav-link text-decoration-none" href="#/">
          <v-img
            class="mt-10 ml-13"
            alt="Logo"
            src="@/assets/images/navBar/OLSLogo.png"
            width="70px"
          />
        </a>

        <v-divider class="mt-5 white"></v-divider>

        <v-list nav dense>
          <v-list-item class="d-flex justify-end my-0">
            <v-icon @click="drawer = !drawer" color="white"
              >mdi-close-thick</v-icon
            >
          </v-list-item>
          <v-list-item-group class="mt-4 white--text" v-model="group">
            <!-- <v-list-item
              class="white--text"
              exact-active-class="transparent orange--text"
              to="/"
            >
              <v-list-item-title> <p></p> </v-list-item-title>
            </v-list-item> -->

            <v-list-item class="white--text">
              <v-list-item-title class="white--text">
                <a
                  class="nav-link white--text text-decoration-none mt-7 hidden-md-and-up"
                  href="http://softidoc.com/"
                  @click="contacts = false"
                  >Home</a
                >
              </v-list-item-title>
            </v-list-item>

            <v-list-item class="white--text">
              <v-list-item-title class="white--text">
                <a
                  class="nav-link white--text text-decoration-none mt-7 hidden-md-and-up"
                  href="#about"
                  @click="contacts = false"
                  >About</a
                >
              </v-list-item-title>
            </v-list-item>

            <v-list-item class="white--text">
              <v-list-item-title class="white--text">
                <a
                  class="nav-link white--text text-decoration-none mt-7 hidden-md-and-up"
                  href="#team"
                  @click="contacts = false"
                  >Team</a
                >
              </v-list-item-title>
            </v-list-item>

            <!-- Client Journey -->
            <v-list-item class="white--text">
              <v-list-item-title class="white--text">
                <a
                  class="nav-link white--text text-decoration-none mt-7 hidden-md-and-up"
                  href="#clientJourney"
                  @click="contacts = false"
                  >Client Journey</a
                >
              </v-list-item-title>
            </v-list-item>

            <!-- log in -->
            <v-list-item class="white--text">
              <v-list-item-title class="white--text">
                <a
                  class="nav-link white--text text-decoration-none mt-7 hidden-md-and-up"
                  href="http://development.softidoc.co.za/"
                  >login</a
                >
              </v-list-item-title>
            </v-list-item>

            <v-list-item class="white--text">
              <v-list-item-title class="white--text">
                <a
                  class="nav-link white--text text-decoration-none mt-7 hidden-md-and-up"
                  href="#contacts"
                  @click="contacts = true"
                  >Contact Us</a
                >
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </v-container>
  </div>
</template>

<script>
// import { mapState, mapMutations } from "vuex";

export default {
  name: "NavBarSection",

  data: () => ({
    drawer: false,
    mini: true,

    about: false,
    team: false,
    journey: false,
    contacts: false,

    group: null,
    items: [
      { title: "About" },
      { title: "Team" },
      { title: "Client Journey" },
      { title: "Contact Us" },
    ],
  }),

  methods: {
    clicked() {
      this.about == true;
      this.team = false;
      this.journey = false;
      this.contacts = false;
    },
  },

  // computed: {
  //   ...mapState(["currentPage"]),
  // },

  // methods: {
  //   ...mapMutations({
  //     setCurrentPage: "setCurrentPage",
  //   }),
  // },
};
</script>

<style lang="scss" scoped>
.OLSLogo {
  width: 139px;
}
.nav {
  position: fixed;
  // justify-content: space-around;
  z-index: 3;
  background-color: white;
  width: 100%;
  // height: auto;
  // border-bottom: 2px solid #82869a;
}
.dot {
  width: 8px;
  height: 8px;
  // border-radius: 20px;
}
.omniLogo {
  margin-top: 1px;
}

//hover dropbar iteam
// .listHover:hover {
//   color: #ffa126;
// }

.sideBar {
  position: fixed;
}
.imgId {
  height: 33px;
  width: 235px;
}
.callBackCard {
  width: 136px;
  margin-top: 25px;
  margin-left: 0px;
  margin-right: 0px;
}
p {
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 26px;
}
@media screen and (max-width: 1265px) {
  .OLSLogo {
    width: 170px;
  }
}
@media screen and (max-width: 1024px) {
  .OLSLogo {
    width: 170px;
  }
  .omniLogo {
    margin-top: 9px;
  }
  p {
    font-size: 15px;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 26px;
  }
}

@media screen and (max-width: 768px) {
  .OLSLogo {
    width: 140px;
  }
  .omniLogo {
    margin-top: 14px;
  }
  .callBackCard {
    margin-top: 25px;
    margin-left: 0px;
  }
  .nav {
    height: 90px;
  }
}

@media screen and (max-width: 425px) {
  .callBackCard {
    margin-top: -42px;
    margin-left: 65px;
  }
  .OLSLogo {
    width: 90px;
  }
}

@media screen and (max-width: 375px) {
  .callBackCard {
    margin-top: -42px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 320px) {
  .callBackCard {
    margin-top: -45px;
    margin-left: 185px;
  }
  .nav {
    height: 80px;
  }
}
</style>
