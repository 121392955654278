<template>
  <div class="primary text-left invisibleCurveyCard">
    <v-card class="transparent" height="100" width="100%" flat></v-card>

    <v-img
      alt="Logo"
      class="mb-3 line1"
      src="@/assets/images/SectionTwo/line1.png"
      contain
      eager
    >
      <v-container>
        <p class="paragraph-p5 mt-10">WHAT WE OFFER</p>
        <h1 class="heading-h1 white--text mt-n3">Our Capabilities</h1>

        <v-row class="mt-16">
          <v-col cols="12" md="3">
            <v-card
              width="100%"
              class="capabilityCard pa-4 white--text rounded-xl"
            >
              <v-img
                alt="Logo"
                class="mb-3"
                src="@/assets/images/SectionTwo/capability/performance.png"
                contain
                width="60"
                height="60"
              />
              <label class="heading-h2"> Performance </label>
              <p class="paragraph-p4 mt-2 white--text">
                Our products combine bank functionality and the most advanced
                cloud-native, cloud agnostic, AI and API-first technology.
              </p>
            </v-card>
          </v-col>

          <v-col cols="12" md="3">
            <v-card
              width="100%"
              class="capabilityCard pa-4 white--text rounded-xl"
            >
              <v-img
                alt="Logo"
                class="mb-3"
                src="@/assets/images/SectionTwo/capability/scale.png"
                contain
                width="60"
                height="60"
              />
              <label class="heading-h2"> Scale </label>
              <p class="paragraph-p4 mt-2 white--text">
                The gains brought on by digital automation lowers operation
                costs and brings long term gain.
              </p>
            </v-card>
          </v-col>

          <v-col cols="12" md="3">
            <v-card
              width="100%"
              class="capabilityCard pa-4 white--text rounded-xl"
            >
              <v-img
                alt="Logo"
                class="mb-3"
                src="@/assets/images/SectionTwo/capability/support.png"
                contain
                width="60"
                height="60"
              />
              <label class="heading-h2"> Support </label>
              <p class="paragraph-p4 mt-2 white--text">
                Access to superior support.
              </p>
            </v-card>
          </v-col>

          <v-col cols="12" md="3">
            <v-card
              width="100%"
              class="capabilityCard pa-4 white--text rounded-xl"
            >
              <v-img
                alt="Logo"
                class="mb-3"
                src="@/assets/images/SectionTwo/capability/adaptability.png"
                contain
                width="60"
                height="60"
              />
              <label class="heading-h2"> Adaptability </label>
              <p class="paragraph-p4 mt-2 white--text">
                Our approach enables innovation through the use and application
                of existing and future data.
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container> <Section3 /> </v-container
    ></v-img>

    <v-container>
      <Section4 />
    </v-container>
    <v-card class="curvedCard white hidden-sm-and-down" height="300" flat>
      <v-container>
        <v-row class="mt-md-16 mt-8">
          <v-col cols="12" md="5">
            <p class="paragraph-p6">WHAT DIFFERENCIATES US</p>
            <h1 class="heading-h1 mt-n3">About Us</h1>
            <p class="paragraph-p4 mt-4">
              We specialise in composite application development and complex
              system intgration leveraging artificial intelligence to address
              banking challenges and needs.
            </p>
          </v-col>
          <v-col class="hidden-sm-and-down" cols="12" md="5"></v-col></v-row
      ></v-container>
    </v-card>
  </div>
</template>

<script>
import Section3 from "@/components/Section3.vue";
import Section4 from "@/components/Section4.vue";
export default {
  name: "HelloWorld",
  data() {
    return {};
  },
  components: {
    Section3,
    Section4,
  },
};
</script>

<style lang="scss" scoped>
//
// .invisibleCurveyCard {
//   border-top-right-radius: 40% 5%;
//   border-top-left-radius: 40% 5%;
// }
.curvedCard {
  border-top-right-radius: 2500px 420px;
  // border-top-left-radius: 1500px 100px;
}
.invisibleCurveyCard {
  border-top-right-radius: 750px 100px;
  border-top-left-radius: 750px 100px;
}

// .invisibleCurveyCard {
//   border-bottom-right-radius: -750px -150px;
//   border-bottom-left-radius: -750px -150px;
// }
.line1 {
  height: 1600px;
}
@media screen and (max-width: 1024px) {
  .line1 {
    height: 1700px;
  }
}
@media screen and (max-width: 768px) {
  .line1 {
    height: 2600px;
  }
  .invisibleCurveyCard {
    border-bottom-right-radius: 50px 70px;
    border-bottom-left-radius: 50px 70px;
  }
}

@media screen and (max-width: 425px) {
  .line1 {
    height: 2850px;
  }
}
</style>
