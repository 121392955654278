<template>
  <div class="text-left">
    <v-container>
      <v-card align="center" class="transparent text-left" flat height="auto">
        <div>
          <section class="white--text">
            <iframe
              width="100%"
              height="700"
              src="https://videos.files.wordpress.com/9yhkCBYy/ai-for-all_-democratizing-data-science-for-financial-inclusion_mp4_hd.mp4"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              eager
            ></iframe>
          </section>
        </div>
      </v-card>
    </v-container>

    <v-container class="">
      <v-card class="transparent hidden-sm-and-up" height="100" flat></v-card>
      <v-img
        alt="Logo"
        class="hidden-sm-and-down"
        src="@/assets/images/SectionTwo/how it works/bankingSystem.png"
        width="100%"
        eager
        contain
      />
      <v-card class="transparent" height="250" flat></v-card>
    </v-container>

    <div class="whyOls white--text hidden-md-and-up">
      <v-container>
        <p lass="paragraph-p5">WHY OLS</p>
        <h2 class="heading-h1">The Process</h2>
      </v-container>

      <v-img
        alt="Logo"
        class="mt-10"
        src="@/assets/images/SectionTwo/how it works/The Process.svg"
        width="100%"
        eager
        contain
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
//
</style>
