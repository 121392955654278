<template>
  <div class="">
    <section class="" id="contacts">
      <v-card
        color=""
        width="100%"
        height="auto"
        class="form transparent pt-md-16"
        flat
        align="center"
      >
        <v-sheet
          title="Click to grap a file from your PC!"
          height="1"
          style="cursor: pointer"
          class="transparent"
        ></v-sheet>

        <v-sheet
          title="Click to grap a file from your PC!"
          style="cursor: pointer"
          color="white"
          class="sheet pa-2 my-16 py-md-16 px-md-3"
        >
          <label class="d-flex paragraph-p6 mt-7"> LET'S MEET UP</label>
          <h1 class="d-flex heading-h1">Contact Us</h1>

          <form ref="form" @submit.prevent="sendEmail()">
            <v-row class="mt-16 px-md-10">
              <v-col cols="12" sm="6">
                <div class="d-flex">
                  <v-img
                    v-if="form.fullName == ''"
                    max-height="18"
                    max-width="16"
                    src="@/assets/images/Section7/personGrey.svg"
                    class="mt-4 mr-n4"
                  ></v-img>

                  <v-img
                    v-else
                    max-height="19"
                    max-width="16"
                    src="@/assets/images/Section7/personWhite.svg"
                    class="mt-4 mr-n4"
                  ></v-img>

                  <input
                    v-model="form.fullName"
                    class="pl-8"
                    type="text"
                    id="fullname"
                    required
                    autocomplete="off"
                    name="full_name"
                    placeholder="Full Name"
                  />
                </div>
              </v-col>

              <v-col cols="12" sm="6">
                <div class="d-flex">
                  <v-img
                    v-if="form.email == ''"
                    max-height="15"
                    max-width="18"
                    src="@/assets/images/Section7/mailGrey.svg"
                    class="mt-4 mr-n5"
                  ></v-img>

                  <v-img
                    v-else
                    max-height="15"
                    max-width="18"
                    contain
                    src="@/assets/images/Section7/mailWhite.svg"
                    class="mt-4 mr-n5"
                  ></v-img>
                  <input
                    v-model="form.email"
                    class="pl-10"
                    autocomplete="off"
                    required
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email"
                  />
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-7 px-md-10">
              <v-col cols="12" sm="6">
                <div class="d-flex">
                  <v-img
                    v-if="form.contactNumber == ''"
                    class="mt-3 mr-n4"
                    max-height="28"
                    max-width="18"
                    src="@/assets/images/Section7/phoneGrey.svg"
                  ></v-img>

                  <v-img
                    v-else
                    class="mt-3 mr-n4"
                    max-height="28"
                    max-width="16"
                    src="@/assets/images/Section7/phoneWhite.svg"
                  ></v-img>
                  <input
                    v-model="form.contactNumber"
                    class="pl-8"
                    type="number"
                    autocomplete="off"
                    id="contactNumber"
                    name="contact_number"
                    placeholder="Contact Number"
                  />
                </div>
              </v-col>

              <v-col cols="12" sm="6">
                <div class="d-flex">
                  <v-img
                    v-if="form.companyName == ''"
                    class="mt-4 mr-n4"
                    max-height="22"
                    max-width="14"
                    src="@/assets/images/Section7/companyGrey.svg"
                  ></v-img>

                  <v-img
                    v-else
                    max-height="22"
                    max-width="14"
                    src="@/assets/images/Section7/companyWhite.svg"
                    class="mt-4 mr-n4"
                  ></v-img>
                  <input
                    v-model="form.companyName"
                    class="pl-9"
                    type="text"
                    required
                    id="company_name"
                    name="company_name"
                    autocomplete="off"
                    placeholder="Company Name"
                  />
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-7 px-md-10">
              <v-col cols="12" sm="12">
                <div class="d-flex">
                  <v-img
                    v-if="form.help == ''"
                    class="mt-4 mr-n3"
                    max-height="17"
                    max-width="18"
                    src="@/assets/images/Section7/messageGrey.svg"
                  ></v-img>

                  <v-img
                    v-else
                    max-height="17"
                    max-width="18"
                    src="@/assets/images/Section7/messageWhite.svg"
                    class="mt-4 mr-n3"
                  ></v-img>

                  <textarea
                    v-model="form.help"
                    autocomplete="off"
                    id="message"
                    class="pl-7"
                    required
                    name="message"
                    rows="5"
                    cols="33"
                    placeholder="How can we help you?"
                  >
                  </textarea>
                </div>
              </v-col>

              <v-col class="" cols="12" md="5">
                <!-- <div
                  class="g-recaptcha"
                  data-sitekey="6LcwFg4iAAAAAJ-nu8v_hcHS1-p8v-vhZqd6mr8W"
                ></div> -->
                <!-- <vue-recaptcha
                  sitekey="6LcwFg4iAAAAAJ-nu8v_hcHS1-p8v-vhZqd6mr8W"
                >
                </vue-recaptcha> -->
              </v-col>

              <v-col class="mt-7" cols="12" md="12">
                <button
                  id="submit"
                  name="submit"
                  onclick="sendEmail()"
                  class="sendBtn white--text"
                >
                  <v-span class="paragraph-btn2 black--text"> SEND</v-span>
                </button>
              </v-col>
            </v-row>
          </form>

          <v-overlay :value="overlay" z-index="10">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-sheet>

        <v-sheet
          title="Click to grap a file from your PC!"
          height="1"
          style="cursor: pointer"
          class="transparent"
        ></v-sheet>
      </v-card>
    </section>

    <v-card class="primary" width="100%" height="300" flat> </v-card>
  </div>
</template>

<script>
// import emailjs from "@emailjs/browser";
import axios from "axios";

// import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "HelloWorld",

  data() {
    return {
      form: {
        fullName: "",
        email: "",
        contactNumber: "",
        companyName: "",
        help: "",
      },

      overlay: false,

      //   error: [],
    };
  },

  // components: { VueRecaptcha },

  methods: {
    async sendEmail() {
      try {
        this.overlay = true;
        var url = process.env.VUE_APP_AI_API_URL;
        var apiKey = process.env.VUE_APP_AI_API_KEY;

        const res = await axios.post(
          url + "emails/send-email",
          {
            name: this.form.fullName,
            email: this.form.email,
            // contact_number: this.form.contactNumber,
            company: this.form.companyName,
            message: this.form.help,
            website_name: "OLS",
          },
          {
            headers: {
              AppApiKey: apiKey,
              // "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.status == 200) {
          // console.log(res);

          this.$swal({
            icon: "success",
            title: "Thank you!",
            text: "Your submission has been sent.",
          });
        }
      } catch (error) {
        const res = error.response;
        const data = res.data;
        const status = error.response.status;
        console.log("error");

        if (status == 400) {
          // console.log(res);

          console.log(res);
          if ("errors" in data) {
            if ("name" in data.errors) {
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: data.errors.name[0],
              });
            } else if ("email" in data.errors) {
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: data.errors.email[0],
              });
            } else if ("message" in data.errors) {
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: data.errors.message[0],
              });
            }
          }
        } else if (status == 404) {
          console.log(res);

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: "Page not found",
            });
          }
        } else if (status == 422) {
          console.log(res);

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }
        } else if (status == 500) {
          console.log(res);
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    // sendEmails() {
    //   var serviceId = process.env.VUE_APP_YOUR_SERVICE_ID;
    //   var templateId = process.env.VUE_APP_YOUR_TEMPLATE_ID;
    //   var publicKey = process.env.VUE_APP_YOUR_PUBLIC_KEY;

    //   this.overlay = true;

    //   if (
    //     this.form.fullName === "" ||
    //     this.form.email === "" ||
    //     this.form.companyName === "" ||
    //     this.form.help === ""
    //   ) {
    //     this.$swal({
    //       icon: "error",
    //       title: "Request failed",
    //       text: "Please fill out all the fields",
    //     });
    //     this.overlay = false;
    //   } else if (!this.validName(this.form.fullName)) {
    //     this.$swal({
    //       icon: "error",
    //       title: "Request failed",
    //       text: "Name must contain only A to Z or a to z",
    //     });
    //     this.overlay = false;
    //   } else if (!this.validEmail(this.form.email)) {
    //     this.$swal({
    //       icon: "error",
    //       title: "Request failed",
    //       text: "Invalid email",
    //     });
    //     this.overlay = false;
    //   } else {
    //     emailjs
    //       .sendForm(serviceId, templateId, this.$refs.form, publicKey)
    //       .then(
    //         (result) => {
    //           this.$swal({
    //             icon: "success",
    //             title: "Thank you!",
    //             text: "Your submission has been sent.",
    //           });

    //           console.log("SUCCESS!", result.text);
    //           this.overlay = false;
    //         },
    //         (error) => {
    //           console.log("error...", error);

    //           this.$swal({
    //             icon: "error",
    //             title: "Request failed",
    //             text: "An internal error occurred during your request!",
    //           });

    //           this.overlay = false;
    //         }
    //       );
    //   }
    // },

    // onloadCallback() {
    //   var response = grecaptcha;
    //   if (response.length == 0) {
    //     print("good");
    //     console.log("good");
    //   } else {
    //     console.log("failed");
    //   }
    // },

    validName: function (name) {
      var nameVar = /^[a-zA-Z\s]+$/;
      return nameVar.test(name);
    },

    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

<style lang="scss" scoped>
//

.sheet {
  width: 978px;
  background: #d3e1ff;
  border-radius: 16px;
}
.contactCard {
  width: 978px;
  border-radius: 10px;
}

.letsMeet {
  font-weight: 300;
  font-size: 18px;
  color: #ffffff;
  line-height: 137.1%;
}
.form {
  margin-bottom: -300px;
  z-index: 1;
}

input,
textarea {
  background: none;
  color: black;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 10px 10px 15px;
  width: 100%;
  border-bottom: 1px solid #c1c1c8;
}
.messageHelp {
  width: 100%;
}
.sendBtn {
  border: 1px solid #3791ff;
  width: 100%;
  height: 50px;
  border-radius: 25px;
}

textarea:focus,
input:focus {
  outline: none;
  border-bottom: 1px solid black;
}

.group {
  position: relative;
}
@media screen and (max-width: 768px) {
  .sheet {
    width: 578px;
  }
}

@media screen and (max-width: 425px) {
  .sheet {
    width: 378px;
  }
}

@media screen and (max-width: 375px) {
  .sheet {
    width: 350px;
  }
}

@media screen and (max-width: 320px) {
  .sheet {
    width: 300px;
  }
}
</style>
