<template>
  <div class="d-flex flex-column align-center justify-center text-left">
    <v-card class="primary white--text" width="100%" flat>
      <v-container class=" ">
        <v-img
          alt="Logo"
          class="AULLogo"
          src="@/assets/images/footer/SoftiDoc.png"
          width="114"
        />
        <v-row>
          <v-col cols="12" md="3"> </v-col>

          <v-col class="" cols="12" md="3">
            <h3 class="heading-h5 mb-4 mt-4">Contact Us</h3>
            <div class="d-flex">
              <v-icon color="white" class="mr-1 mt-n3">
                mdi-email-outline
              </v-icon>
              <p class="paragraph-p4">info@softidoc.co.za</p>
            </div>

            <div class="d-flex">
              <v-icon color="white" class="mr-1 mt-n2"> mdi-cellphone </v-icon>
              <p class="paragraph-p4 mt-1">+27 84 326 0928</p>
            </div>
          </v-col>

          <v-col class="" cols="12" md="3">
            <h3 class="heading-h5 mb-4 mt-4">Location SA</h3>
            <div class="d-flex">
              <v-icon color="white" class="mr-1 mt-1 mb-auto">
                mdi-map-marker-outline
              </v-icon>
              <p class="paragraph-p4">
                Suite 15, Block 10<br />
                Boardwalk Office Park<br />
                Eros Road<br />
                Olympus AH, 0081
              </p>
            </div>
          </v-col>

          <v-col class="" cols="12" md="3">
            <h3 class="heading-h5 mb-4 mt-4">Location UK</h3>
            <div class="d-flex">
              <v-icon color="white" class="mr-1 mt-1 mb-auto">
                mdi-map-marker-outline
              </v-icon>
              <p class="paragraph-p4">
                32 Alpine Avenue<br />
                Tolworth<br />
                London<br />
                KT59RJ
              </p>
            </div>
          </v-col>

          <v-col class="paragraph mt-3" cols="12" md="12">
            <hr class="hr" />
            <p class="mt-5 paragraph-p8">
              <!-- Copyright ©SoftiDoc, All rights reserved. Suite 15, Block 10,
                Boardwalk Office Park, Eros Road, Olympus AH, 0081 -->
              SoftiDoc © Copyright - All rights reserved - 2022
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "HelloWorld",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
//

.manualDataCard {
  //   border-top-right-radius: 100px 70px;
  border-top-right-radius: 100px 70px;
}
.hr {
  border-color: #d3e1ff;
}
p {
  // font-weight: 100;
  font-size: 16px;
  line-height: 32px;
}
.contactDetails {
  font-weight: 700;
  font-size: 24px;
  line-height: 26.11px;
}
.Details {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}
.copyRight {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}
@media screen and (max-width: 320px) {
  .Details {
    font-size: 19px;
    line-height: 24px;
  }
  .contactDetails {
    font-size: 20px;
    line-height: 20px;
  }
}
</style>
