<template>
  <div class="aboutDev text-left">
    <!-- <div class="whyOls white--text hidden-md-and-up">
      <v-container>
        <p lass="paragraph-p5">WHY OLS</p>
        <h2 class="heading-h1">The Process</h2>
      </v-container>

      <v-img
        alt="Logo"
        class="mt-10"
        src="@/assets/images/SectionTwo/how it works/The Process.svg"
        width="100%"
        eager
        contain
      />
    </div> -->
    <!-- <div class="curvey-White-Background">
      <v-img
        alt="Logo"
        class=""
        src="@/assets/images/SectionTwo/whiteBackground.png"
        width="100%"
        contain
      ></v-img>
    </div> -->

    <section id="about">
      <v-container>
        <v-row class="mt-md-16 mt-8">
          <v-col class="hidden-md-and-up" cols="12" md="5">
            <p class="paragraph-p6">WHAT DIFFERENCIATES US</p>
            <h1 class="heading-h1 mt-n3">About Us</h1>
            <p class="paragraph-p4 mt-4">
              We specialise in composite application development and complex
              system intgration leveraging artificial intelligence to address
              banking challenges and needs.
            </p>
          </v-col>
          <!-- <v-col class="hidden-sm-and-down" cols="12" md="5"> </v-col> -->
          <v-col class="hidden-sm-and-down" cols="12" md="10"></v-col>

          <v-col class="mt-md-16" cols="12" md="5">
            <v-img
              alt="Logo"
              class=""
              src="@/assets/images/Section5/jerry.png"
              width="100%"
              eager
              contain
            />
          </v-col>
          <v-col class="hidden-sm-and-down" cols="12" md="2"></v-col>

          <v-col class="mt-md-16" cols="12" md="5">
            <p class="paragraph-p6">Artificial Intelligence Solutions</p>
            <h2 class="heading-h3 mt-n1">
              We are an AI solutions provider specialising in a boutique of
              products to achieve financial inclusion.
            </h2>
            <p class="paragraph-p3 mt-5">
              Artifical intelligence and Cognitive Computing focuses on
              understanding and revealing the power of real-time data through
              the use of cognitive systems and then using this information to
              enhance business processes and decision making. This approach
              enables innovation through the use and application of existing and
              future data.
            </p>
          </v-col>

          <v-col class="mt-md-16 hidden-md-and-up" cols="12" md="5">
            <v-img
              alt="Logo"
              class=""
              src="@/assets/images/Section5/SherlockDegital.png"
              width="100%"
              contain
              eager
            />
          </v-col>

          <v-col class="mt-md-16" cols="12" md="5">
            <p class="paragraph-p6">Digital core banking</p>
            <h2 class="heading-h3">
              Digital core banking system that incoporates all our AI prebuilt
              boutique components.
            </h2>
            <p class="paragraph-p3 mt-5">
              This system dramatically accelerates the process. Our products
              combine bank functionality and the most advanced cloud-native,
              cloud agnostic, AI and API-first technolgy to help our clients go
              live faster. Also toward the vision of the complete digital bank,
              the gains brought on by digital automation lowers operation costs
              and brings long term gain allowing services to people who are not
              visible in traditional financial systems.
            </p>
          </v-col>

          <v-col class="hidden-sm-and-down" cols="12" md="2"></v-col>
          <v-col class="mt-md-16 hidden-sm-and-down" cols="12" md="5">
            <v-img
              alt="Logo"
              class=""
              src="@/assets/images/Section5/SherlockDegital.png"
              width="100%"
              contain
              eager
            />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
//
.whyOls {
  margin-top: -280px;
}
.curvey-White-Background {
  margin-top: -200px;
}
// @media screen and (max-width: 768px) {
//   .whyOls {
//     margin-top: -335px;
//   }
// }
// .aboutDev {
//   margin-top: -200px;
// }
</style>
