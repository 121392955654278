<template>
  <v-app>
    <v-main class="top-section text-center">
      <NavBav />
      <Section1 />
      <Section2 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import NavBav from "@/components/Bars/NavBar.vue";
import Section1 from "@/components/Section1.vue";
import Section2 from "@/components/Section2.vue";
import Section5 from "@/components/Section5.vue";
import Section6 from "@/components/Section6.vue";
import Section7 from "@/components/Section7.vue";
import Footer from "@/components/Bars/Footer.vue";

export default {
  name: "App",
  components: {
    NavBav,
    Section1,
    Section2,
    Section5,
    Section6,
    Section7,
    Footer,
  },
};
</script>
