import Vue from "vue";
import VueRouter from "vue-router";
import SectionOne from "../views/SectionOne.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: SectionOne,
  },
  // {
  //   path: '/about',
  //   name: 'about',

  //   component: () => import( '../views/AboutView.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  // linkExactActiveClass: "active",
  // scrollBehavior() {
  //   return { x: 0, y: 0 };
  // },
});

export default router;
