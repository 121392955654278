<template>
  <div class="text-left">
    <v-card
      class="transparent hidden-sm-and-down"
      height="100"
      width="100%"
      flat
    ></v-card>

    <v-container>
      <section id="learnMore">
        <v-row class="white--text">
          <!-- second row -->
          <v-col class="mt-md-16" cols="12" md="5">
            <p class="paragraph-p5 mt-md-10">WHY OLS</p>
            <h1 class="heading-h1">How It Works</h1>
            <p class="paragraph-p2 mt-3">
              OLS is the leading online financial platform that offers short
              term financial services and is also a Fintech provider.
            </p>
          </v-col>
          <v-col class="mt-16 hidden-sm-and-down" cols="12" md="5"></v-col>
          <v-col class="mt-16" cols="12" md="5">
            <div class="d-flex">
              <div>
                <v-img
                  alt="Logo"
                  class=""
                  src="@/assets/images/SectionTwo/how it works/digital.png"
                  width="24px"
                  contain
                />
              </div>
              <div class="ml-4">
                <h1 class="heading-h2">Digital Core Banking</h1>
                <p class="paragraph-p4 mt-3">
                  With the transition of banks now moving to digital we have
                  developed a digital core banking system that incorporates all
                  our AI prebuilt boutique components as to dramatically
                  accelerate the loan application process and other features.
                </p>
              </div>
            </div>
          </v-col>
          <v-col class="hidden-sm-and-down" cols="12" md="1"></v-col>
          <v-col class="mt-16" cols="12" md="5">
            <div class="d-flex">
              <div>
                <v-img
                  alt="Logo"
                  class=""
                  src="@/assets/images/SectionTwo/how it works/Vector.png"
                  width="38px"
                  height="30px"
                  contain
                />
              </div>
              <div class="ml-3">
                <h1 class="heading-h2">Digital Automation</h1>
                <!-- <v-card> -->
                <!-- <v-img
                  alt="Logo"
                  class="hidden-sm-and-down my-3"
                  src="@/assets/images/SectionTwo/how it works/ellipse.png"
                  height="100"
                  contain
                /> -->
                <!-- </v-card> -->

                <p class="paragraph-p4 mt-3">
                  Our products combine bank functionality and the most advanced
                  cloud-native, cloud agnostic, AI and API-first technology to
                  help our clients go live faster. Also, toward the vision of
                  the complete digital bank, the gains brought on by digital
                  automation lowers operation costs and brings long term gain
                  allowing services to people who are not visible in traditional
                  financial systems as well as bank the unbanked.
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </section>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
//
</style>
