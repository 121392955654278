<template>
  <div class="text-left">
    <!-- <v-card class="transparent hidden-sm-and-down" height="150" flat></v-card> -->

    <section id="team">
      <v-container class="">
        <v-row>
          <v-col class="hidden-sm-and-down" cols="12" md="6"></v-col>

          <v-col class="" cols="12" md="6">
            <div class="mainClass">
              <div class="imageClass hidden-sm-and-down">
                <v-img
                  alt="Logo"
                  class=""
                  src="@/assets/images/Section6/Arrow.png"
                  width="206px"
                  contain
                />
              </div>
              <div class="imageClass2 hidden-md-and-up">
                <v-img
                  alt="Logo"
                  class=""
                  src="@/assets/images/SectionOne/Arrow3.png"
                  width="120px"
                  contain
                />
              </div>

              <div class="mt-9">
                <v-container>
                  <p class="paragraph-p6 mt-md-16">MEET THE AI TEAM</p>
                  <h2 class="heading-h4">Pre-built Personalities</h2>
                  <p class="paragraph-p4">
                    Some awesome content here about how versatile and great the
                    bots are, they are almost like humans!
                  </p>
                </v-container>
              </div>

              <div class="ml-n10 hidden-md-and-up">
                <!-- <v-img
                  alt="Logo"
                  class="mr-n16"
                  src="@/assets/images/SectionOne/Arrow3.png"
                  width="190px"
                  contain
                /> -->
              </div>
            </div>
          </v-col>

          <!-- Paica AND Alec -->

          <v-col class="hidden-sm-and-down" cols="12" md="1"></v-col>
          <v-col class="mt-10" cols="12" md="5">
            <v-card class="pa-8 personalityCard" flat>
              <div class="d-flex">
                <div>
                  <v-img
                    alt="Logo"
                    class=""
                    src="@/assets/images/Section6/person1.png"
                    width="60px"
                    contain
                  />
                </div>
                <div class="ml-2">
                  <h3 class="heading-h2">Paica</h3>
                  <p class="paragraph-p6">Collections ALgorithm</p>
                </div>
              </div>

              <p class="paragraph-p4">
                To boost the efficiency of collections, SoftiDoc Artificial
                Intelligence Collections Algorithm (PAICA) takes proactive steps
                to reduce the occurrence instance of missed payments by
                monitoring client credit profiles. PAICA continually monitors
                client credit profiles and other data sources to maximise
                collections.
              </p>
              <v-card
                class="px-3 py-4 d-flex blue--text personalitySmallCard"
                flat
              >
                <div>
                  <v-img
                    alt="Logo"
                    class="mr-2 mt-2"
                    src="@/assets/images/Section6/mark.png"
                    width="20px"
                    contain
                  />
                </div>
                <p class="paragraph-p4">
                  Significant improvement in collection rates.
                </p>
              </v-card>
            </v-card>
          </v-col>

          <v-col class="mt-10" cols="12" md="5">
            <v-card class="pa-8 personalityCard" flat>
              <div class="d-flex">
                <div>
                  <v-img
                    alt="Logo"
                    class=""
                    src="@/assets/images/Section6/person2.png"
                    width="60px"
                    contain
                  />
                </div>
                <div class="ml-2">
                  <h3 class="heading-h2">Alec</h3>
                  <p class="paragraph-p6">Automated classification</p>
                </div>
              </div>

              <p class="paragraph-p4">
                Towards the goal of end-to-end loan applications and a fully
                digital experience, Automated SoftiDoc Income and Expenses
                calculator (ALEC) implements Optical Character Recognition (OCR)
                that streamlines the digitisation of bank statements and
                automatically classifies income and expense transactions that
                are used to calculate affordability.
              </p>
              <v-card
                class="px-3 py-4 d-flex blue--text personalitySmallCard"
                flat
              >
                <div>
                  <v-img
                    alt="Logo"
                    class="mr-2 mt-2"
                    src="@/assets/images/Section6/mark.png"
                    width="20px"
                    contain
                  />
                </div>
                <p class="paragraph-p4">
                  Flagging of suspicious loan applications.
                </p>
              </v-card>
            </v-card>
          </v-col>
          <v-col class="hidden-sm-and-down" cols="12" md="1"></v-col>

          <!-- Jerry AND Sally -->

          <v-col class="hidden-sm-and-down" cols="12" md="1"></v-col>
          <v-col class="mt-5" cols="12" md="5">
            <v-card class="pa-8 personalityCard" flat>
              <div class="d-flex">
                <div>
                  <v-img
                    alt="Logo"
                    class=""
                    src="@/assets/images/Section6/person3.png"
                    width="60px"
                    contain
                  />
                </div>
                <div class="ml-2">
                  <h3 class="heading-h2">Jerry</h3>
                  <p class="paragraph-p6">Credit Scoring</p>
                </div>
              </div>

              <p class="paragraph-p4">
                Predicts the probability that a person will repay a loan, by
                training on historical loan outcomes - combines credit bureau
                and other data sources as input. Able to combine thousands of
                different data features to predict a probability.
              </p>
              <v-card
                class="px-3 py-4 d-flex blue--text personalitySmallCard"
                flat
              >
                <div>
                  <v-img
                    alt="Logo"
                    class="mr-2 mt-2"
                    src="@/assets/images/Section6/mark.png"
                    width="20px"
                    contain
                  />
                </div>
                <p class="paragraph-p4">
                  Significant improvement in loan default rates.
                </p>
              </v-card>
            </v-card>
          </v-col>

          <v-col class="mt-5" cols="12" md="5">
            <v-card class="pa-8 personalityCard" flat>
              <div class="d-flex">
                <div>
                  <v-img
                    alt="Logo"
                    class=""
                    src="@/assets/images/Section6/person4.png"
                    width="60px"
                    contain
                  />
                </div>
                <div class="ml-2">
                  <h3 class="heading-h2">Sally</h3>
                  <p class="paragraph-p6">Intelligent Chatbot</p>
                </div>
              </div>

              <p class="paragraph-p4">
                To streamline operations, Sally is a text-based virtual
                assistant that assists customers during the loan application
                process. Its text-based interface on WhatsApp and Facebook
                Messenger aims to enhance and personalise the user experience.
              </p>
              <v-card
                class="px-3 py-4 d-flex blue--text personalitySmallCard"
                flat
              >
                <div>
                  <v-img
                    alt="Logo"
                    class="mr-2 mt-2"
                    src="@/assets/images/Section6/mark.png"
                    width="20px"
                    contain
                  />
                </div>
                <p class="paragraph-p4">
                  Automated support and responses to questions, assistance with
                  loan applications and alternative way to e.g. browse a website
                  (through a text interface).
                </p>
              </v-card>
            </v-card>
          </v-col>
          <v-col class="hidden-sm-and-down" cols="12" md="1"></v-col>

          <!-- Sherlock AND OCR -->

          <v-col class="hidden-sm-and-down" cols="12" md="1"></v-col>
          <v-col class="mt-5 mt-xl-n6" cols="12" md="5">
            <v-card class="pa-8 personalityCard" flat>
              <div class="d-flex">
                <div>
                  <v-img
                    alt="Logo"
                    class=""
                    src="@/assets/images/Section6/person5.png"
                    width="60px"
                    contain
                  />
                </div>
                <div class="ml-2">
                  <h3 class="heading-h2">Sherlock</h3>
                  <p class="paragraph-p6">Fraud Detection & Prevention</p>
                </div>
              </div>

              <p class="paragraph-p4">
                An AI fraud detection system, Sherlock is able to characterise
                client responses behaviour and detect anomalous behaviour by
                monitoring various data sources in the prevention of fraud. This
                system monitors a variety of data sources.
              </p>
              <v-card
                class="px-3 py-4 d-flex blue--text personalitySmallCard"
                flat
              >
                <div>
                  <v-img
                    alt="Logo"
                    class="mr-2 mt-2"
                    src="@/assets/images/Section6/mark.png"
                    width="20px"
                    contain
                  />
                </div>
                <p class="paragraph-p4">
                  Flagging of suspicious loan applications
                </p>
              </v-card>
            </v-card>
          </v-col>

          <v-col class="mt-5" cols="12" md="5">
            <v-card class="pa-8 personalityCard" flat>
              <div class="d-flex">
                <div>
                  <v-img
                    alt="Logo"
                    class=""
                    src="@/assets/images/Section6/person6.png"
                    width="60px"
                    contain
                  />
                </div>
                <div class="ml-2">
                  <h3 class="heading-h2">OCR</h3>
                  <p class="paragraph-p6">Optical Character Recognition</p>
                </div>
              </div>

              <p class="paragraph-p4">
                The OCR tool was built to enhance the process to collect data
                from internet generated bank statements and then using this data
                to define spend and affordability.
              </p>
              <P class="paragraph-p4"
                >Various template design have been built on the five major banks
                in South Africa taking into consideration the various zones and
                line item descriptions.
              </P>
              <v-card
                class="px-3 py-4 d-flex blue--text personalitySmallCard"
                flat
              >
                <div>
                  <v-img
                    alt="Logo"
                    class="mr-2 mt-2"
                    src="@/assets/images/Section6/mark.png"
                    width="20px"
                    contain
                  />
                </div>
                <p class="paragraph-p4">Loan process reduction time</p>
              </v-card>
            </v-card>
          </v-col>
          <v-col class="hidden-sm-and-down" cols="12" md="1"></v-col>
        </v-row>
      </v-container>
    </section>

    <section id="clientJourney">
      <v-container>
        <v-card
          class="transparent hidden-sm-and-down"
          height="110"
          flat
        ></v-card>
        <v-img
          alt="Logo"
          class="hidden-sm-and-down"
          src="@/assets/images/Section6/Client-Journey.png"
          width="100%"
          eager
          contain
        />
        <div class="hidden-md-and-up">
          <v-card class="transparent" flat height="90"></v-card>
          <p>Our Process</p>
          <h1>Client Journey</h1>
          <p>
            We specialise in composite application development and complex
            system intgration leveraging artificial intelligence to address
            banking challenges and needs.
          </p>

          <v-img
            alt="Logo"
            src="@/assets/images/Section6/JourneyMobile.png"
            width="100%"
            contain
            eager
          />
        </div>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
//
.personalityCard {
  border-radius: 16px;
  background: #d3e1ff;
}
.personalitySmallCard {
  border-radius: 16px;
}
.mainClass {
  position: relative;
}
.imageClass {
  position: absolute;
  top: 100px;
  left: -190px;
}
.imageClass2 {
  position: absolute;
  top: 30px;
  right: -25px;
}

@media screen and (max-width: 768px) {
  .imageClass2 {
    top: 60px;
    right: -10px;
  }
}

@media screen and (max-width: 425px) {
  .imageClass2 {
    top: 20px;
    right: -18px;
  }
}

@media screen and (max-width: 375px) {
  .imageClass2 {
    top: 30px;
    right: -18px;
  }
}
</style>
